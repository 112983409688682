import { ACCESS_TOKEN_KEY } from './init.constants'

export const getAuthLocalStorage = () => {
  try {
    const authData = localStorage.getItem(ACCESS_TOKEN_KEY)
    return authData && JSON.parse(authData)
  } catch (error) {}
}

export const getAccessToken = () => {
  const data = getAuthLocalStorage()
  return data?.accessToken || ''
}

export const isValidPassword = () => {
  const regex = /^\S{6,14}$/
  return regex
}

export const isValidYear = () => {
  const regex = /^\S{4,}$/
  return regex
}

export const isValidEmail = () => {
  const regex = /^(?![@.])[\w.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/
  return regex
}

export const isValidPostalCode = () => {
  const regex = /^(?=.*\d).{7}$/
  return regex
}

export const isRegexSpecialCharacter = () => {
  const regex = /^[^`~!@#$%^&*()_+=[\]{}|\\:;"'<>,.?/]*$/
  return regex
}

export const isRegexFurigana = () => {
  const regex = /^[^a-zA-Z]*$/
  return regex
}
export const isRegexNumber = () => {
  const regex = /^[0-9\b]+$/
  return regex
}
export const isRegexNumberB3 = () => {
  const regex = /^[1-9][0-9]*$/
  return regex
}
export const isRegexEmoji = () => {
  const regex = /[\u{1F600}-\u{1F64F}]/u
  return regex
}

const emojiRegex = /[\u{1F600}-\u{1F64F}]/u

export const validateNoEmoji = (_, value) => {
  if (emojiRegex.test(value)) {
    return Promise.reject(new Error('絵文字を入力しないでください'))
  }
  return Promise.resolve()
}
export const validatePasswordEmoji = async (_, value) => {
  await validateNoEmoji(_, value)
  const isValidPassword = /^\S{6,14}$/
  if (value && !isValidPassword.test(value)) {
    return Promise.reject(
      new Error('パスワードは6文字から14文字で入力してください'),
    )
  }
  return Promise.resolve()
}

export const validateImageUrl = (_, value) => {
  const imageRegex = /\.(jpeg|jpg|gif|png|svg)$/i
  // const isValid = value && imageRegex.test(value)

  // if (value && isValid) {
  //   return Promise.resolve()
  // } else {
  //   return Promise.reject(new Error('有効な画像URLを入力してください'))
  // }
  if (value && !imageRegex.test(value)) {
    return Promise.reject(new Error('有効な画像URLを入力してください'))
  }
  return Promise.resolve()
}

export const validateVideoUrl = (_, value) => {
  const videoRegex = /\.(mp4|avi|mov|wmv|flv|mkv)$/i
  if (value && !videoRegex.test(value)) {
    return Promise.reject(new Error('有効な動画URLを入力してください'))
  }
  return Promise.resolve()
}
